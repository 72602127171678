body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.tttop {
  color: green
}
.tthave {
  color: blue
}
.zcont {
  width: 100%;
  height: calc( 100vh -64px);
}
.zleft {
   width: 20%;
   height: 100%;
   float:left;
   overflow: auto;
}
.zmain {
  float: right;
  width: 80%;
  height: 100%;
  overflow: auto;
}
.outer-plate {
  margin:10px;
}
.inner-plate {
  padding: 10px;
}
.slabel {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1;
}
.ds_block {
  margin: 10px;
}
.marg10 {
  margin: 10px !important;
}
.ac_menu {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    z-index: 1000;
    max-height: calc(100vh / 2);
    overflow: auto;
    border: 1px solid #cccccc;
}
.ac_item {
    padding: 2px 6px;
    cursor: default;
}
.fld-name {
    font-style: oblique;
    font-weight: bold;
    display: block;
}
.fld-type {
    font-style: oblique;
    font-weight: bold;
    display: block;
    float: right;
}
.fld-human {
    font-style: normal;
    display: block;
}
.fld-descr {
    font-style: normal;
}
.fld-link {
    font-style: oblique;
}
.fld-narated {
  min-width: 200px;
  width: 100%;
}
.fld-extra {
  min-width: 200px;
}
.fld-icons {
  float:right;
}
.orig {
  color: blue
}
.ico-primary-key {
  color: blue
}
.ico-null {
  color: grey;
}
.ico-indexed {
  color: green;
}
.ico-reference {
  color: red;
}
